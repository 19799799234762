@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,700;1,600&display=swap");

body {
  font-family: "Mulish", sans-serif;
}
#root{
  height: 100vh;
}
iframe {
  pointer-events: none;
}

.error-text {
  font-size: 12px;
  color: #ff4d4f;
  text-align: left;
}
